<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-title>Calendário de consultas</v-card-title>
        <v-form class="multi-col-validation">
            <v-card-text class="pt-5">
                <v-row>

                    <v-col cols="12" md="12">
                        <v-row class="fill-height">
                            <v-col>
                                <v-sheet height="64">
                                    <v-toolbar flat>
                                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                                            Hoje
                                        </v-btn>
                                        <v-btn fab text small color="grey darken-2" @click="prev">
                                            <v-icon small>
                                                {{ icons.mdiChevronLeft }}
                                            </v-icon>
                                        </v-btn>
                                        <v-btn fab text small color="grey darken-2" @click="next">
                                            <v-icon small>
                                                {{ icons.mdiChevronRight }}
                                            </v-icon>
                                        </v-btn>
                                        <v-toolbar-title v-if="$refs.calendar">
                                            {{ $refs.calendar.title }}
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-menu bottom right>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                                    <span>{{ typeToLabel[type] }}</span>
                                                    <v-icon right>
                                                        {{ icons.mdiMenuDown }}
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click="type = 'month'">
                                                    <v-list-item-title>Meses</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="type = 'week'">
                                                    <v-list-item-title>Semanas</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="type = 'day'">
                                                    <v-list-item-title>Dias</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="type = '4day'">
                                                    <v-list-item-title>4 dias</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-toolbar>
                                </v-sheet>
                                <v-sheet height="600">
                                    <v-calendar ref="calendar" v-model="focus" color="primary" :events="events"
                                        :event-color="getEventColor" :type="type" @click:event="showEvent"
                                        :weekday-format="getDay" @click:more="viewDay" @click:date="viewDay"
                                        @change="updateRange"></v-calendar>

                                    <v-dialog v-model="selectedOpen" :close-on-content-click="false"
                                        :activator="selectedElement" offset-x persistent max-width="1000px">
                                        <v-card min-width="350px">
                                            <v-toolbar>
                                                <v-btn icon @click="selectedOpen = false; tab = 0">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                                <v-toolbar-title
                                                    v-html="consulta.hora + ' - ' + consulta.paciente.paciente"
                                                    v-if="consulta.paciente.paciente != ''">
                                                </v-toolbar-title>
                                                <v-toolbar-title v-html="'Cadastro de consulta'" v-else>
                                                </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn icon @click="cadastrarConsulta()">
                                                    <v-icon>{{ icons.mdiContentSave }}</v-icon>
                                                </v-btn>
                                                <v-btn icon @click="dialogDelete = true" v-if="consulta.id != null">
                                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>

                                            <v-tabs v-model="tab" show-arrows>
                                                <v-tab v-for="tab in tabs" :key="tab.id">
                                                    <span>{{ tab.title }}</span>
                                                </v-tab>
                                            </v-tabs>

                                            <v-tabs-items v-model="tab">
                                                <v-tab-item>
                                                    <v-card flat class="pa-3 mt-2">
                                                        <v-card-text>
                                                            <v-form class="multi-col-validation ">
                                                                <v-row>

                                                                    <v-col md="6" cols="12">
                                                                        <v-select v-model="consulta.idPaciente"
                                                                            :items="pacientes"
                                                                            :disabled="consulta.id != null"
                                                                            item-value="id" item-text="paciente"
                                                                            label="Paciente *" outlined dense>
                                                                        </v-select>
                                                                    </v-col>

                                                                    <v-col md="6" cols="12">
                                                                        <v-select v-model="consulta.idDentista"
                                                                            :items="dentistas"
                                                                            :disabled="consulta.id != null"
                                                                            item-value="id" item-text="dentista"
                                                                            label="Profissional *" outlined dense>
                                                                        </v-select>

                                                                    </v-col>

                                                                    <v-col md="6" cols="12">
                                                                        <v-text-field v-model="consulta.consulta"
                                                                            label="Consulta *" dense outlined
                                                                            ref="consulta">
                                                                        </v-text-field>
                                                                    </v-col>

                                                                    <v-col md="6" cols="12">
                                                                        <v-menu v-model="menu1"
                                                                            :close-on-content-click="false"
                                                                            transition="scale-transition" offset-y
                                                                            max-width="290px" min-width="auto">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-text-field
                                                                                    v-model="computedDateFormatted"
                                                                                    dense outlined
                                                                                    label="Dia da consulta" readonly
                                                                                    v-bind="attrs" v-on="on">
                                                                                </v-text-field>
                                                                            </template>
                                                                            <v-date-picker v-model="date" no-title
                                                                                @input="menu2 = false"></v-date-picker>
                                                                        </v-menu>
                                                                    </v-col>

                                                                    <v-col md="6" cols="12">
                                                                        <v-text-field v-model.lazy="consulta.valor"
                                                                            :disabled="consultaPagamento.total && consulta.id != null"
                                                                            label="Valor *" v-money="money" dense
                                                                            outlined ref="valor">
                                                                        </v-text-field>
                                                                    </v-col>

                                                                    <v-col class="d-flex" cols="12" sm="6">
                                                                        <v-select v-model="consulta.confirmacao"
                                                                            :items="tipos" item-value="id"
                                                                            item-text="name" label="Confirmação"
                                                                            outlined dense></v-select>
                                                                    </v-col>

                                                                    <v-col md="6" cols="12">
                                                                        <v-text-field v-model="consulta.hora"
                                                                            v-mask="'##:##'" label="Hora inicio"
                                                                            outlined dense>
                                                                        </v-text-field>
                                                                    </v-col>

                                                                    <v-col md="6" cols="12">
                                                                        <v-text-field v-model="consulta.horaTermino"
                                                                            label="Hora término" v-mask="'##:##'"
                                                                            outlined dense>
                                                                        </v-text-field>
                                                                    </v-col>

                                                                    <v-col class="d-flex" cols="12" sm="6">
                                                                        <v-select v-model="consulta.inativo"
                                                                            :disabled="consulta.id == null"
                                                                            :items="inativos" item-value="id"
                                                                            item-text="name" label="Inativo" outlined
                                                                            dense></v-select>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-form>

                                                            <span v-html="selectedEvent.details"></span>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-tab-item>


                                                <v-tab-item>
                                                    <v-card flat class="pa-3 mt-2">
                                                        <v-card-text>
                                                            <v-form class="multi-col-validation">
                                                                <v-row>
                                                                    <v-col md="4" cols="12">
                                                                        <v-text-field v-model.lazy="valorPagar"
                                                                            label="Valor a ser pago*" v-money="money1"
                                                                            dense outlined>
                                                                        </v-text-field>
                                                                    </v-col>

                                                                    <v-col class="d-flex" cols="12" sm="4">
                                                                        <v-select v-model="consultaPagamento.tipo"
                                                                            :items="tiposPagamento" item-value="id"
                                                                            item-text="name" label="Permissão" outlined
                                                                            dense></v-select>
                                                                    </v-col>

                                                                    <v-col md="4" cols="12" class="text-center">
                                                                        <v-btn color="primary"
                                                                            @click="salvarPagamento()" block
                                                                            :disabled="consultaPagamento.total">
                                                                            Salvar pagamento
                                                                        </v-btn>
                                                                    </v-col>

                                                                    <v-col class="d-flex mt-3" cols="12" md="12">
                                                                        <p class="text-left">
                                                                            Valor da consulta:
                                                                            <b>{{ totalConsulta }}</b> -
                                                                            Total pago:
                                                                            <b>{{ totalPago }}</b>
                                                                        </p>
                                                                    </v-col>

                                                                    <v-col md="12" cols="12">
                                                                        <v-data-table :headers="headers"
                                                                            :items="consultaPagamentos"
                                                                            class="elevation-1" :footer-props="{
                                                                                showFirstLastPage: true,
                                                                                'items-per-page-text': 'Dados por página'
                                                                            }">
                                                                            <template v-slot:no-data>
                                                                                Nenhum pagamento efetuado
                                                                            </template>
                                                                        </v-data-table>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-form>

                                                            <span v-html="selectedEvent.details"></span>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-tab-item>
                                            </v-tabs-items>


                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <v-card class="text-center">
                                            <v-card-text class="text-h5 text-center">Deseja deletar a consulta ?
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="warning" outlined @click="dialogDelete = false">Cancelar
                                                </v-btn>
                                                <v-btn color="success" outlined @click="deletarConsulta">OK</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-form>

        <v-snackbar v-model="snackbar" :color="color" class="text-center">
            {{ text }}
        </v-snackbar>
    </v-card>
</template>

<script>
import Vue from "vue";
import { ref } from '@vue/composition-api'
import { mdiClose, mdiContentSave, mdiDelete, mdiPencil, mdiChevronLeft, mdiChevronRight, mdiMenuDown, mdiHeart, mdiDotsVertical } from '@mdi/js'

import { VMoney } from 'v-money'
import { mask } from 'vue-the-mask'

export default {
    directives: { money: VMoney, money1: VMoney, mask },

    setup() {
        return {
            dialog: false,
            dialogDelete: false,
            menu1: false,

            date: "",
            dateFormatted: "",
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            money1: {
                decimal: ',',
                thousands: '.',
                precision: 2,
            },

            tab: "",
            tabs: [
                { title: 'Dados Consulta', id: 0 },
                { title: 'Pagamentos', id: 1 },
            ],

            time: null,
            menu2: false,
            menu3: false,

            icons: {
                mdiClose,
                mdiDelete,
                mdiContentSave,
                mdiPencil,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMenuDown,
                mdiHeart,
                mdiDotsVertical
            },
            snackbar: false,
            text: '',
            color: 'success',

            pacientes: [],
            dentistas: [],
            tipos: [{ id: 1, name: "Não informado" }, { id: 2, name: "Confirmado" }, { id: 3, name: "Não confirmado" }],
            tiposPagamento: [{ id: 0, name: "Dinheiro" }, { id: 1, name: "Cartão de crédito" }, { id: 2, name: "Cartão de débito" }, { id: 3, name: "Pix" }],
            inativos: [{ id: 0, name: "Não" }, { id: 1, name: "Sim" }],
            consultas: [],
            consultaPagamentos: [],
            totalPago: 0,
            totalConsulta: 0,
            valorPagar: 0,
            consultaPagamento: { idCconsulta: 0, valor: 0, tipo: 0, token: localStorage.getItem("token"), total: false },
            novaConsulta: true,
            consulta: {
                confirmacao: 1,
                consulta: "",
                consultaPagamentos: {},
                createdAt: "",
                data: "",
                dentistum: { dentista: '' },
                hora: "",
                horaTermino: "",
                id: 0,
                idDentista: 0,
                idEmpresa: 0,
                idPaciente: 0,
                paciente: { paciente: '' },
                token: "",
                updatedAt: "",
                valor: "",
            },

            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Meses',
                week: 'Semanas',
                day: 'Dias',
                '4day': '4 Dias',
            },
            selectedEvent: {
                consulta: {
                    confirmacao: 1,
                    consulta: "",
                    consultaPagamentos: {},
                    createdAt: "",
                    data: "",
                    dentistum: { dentista: '' },
                    hora: "",
                    horaTermino: "",
                    id: 0,
                    idDentista: 0,
                    idEmpresa: 0,
                    idPaciente: 0,
                    paciente: { paciente: '' },
                    token: "",
                    updatedAt: "",
                    valor: "",
                }
            },
            selectedElement: null,
            selectedOpen: false,
            events: [],
            colors: ['success', 'error', 'warning'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            dialogDelete: false,

            headers: [
                {
                    text: 'Valor pago',
                    align: 'start',
                    value: 'valor',
                    sortable: false
                },
                { text: 'Tipo de pagamento', align: 'start', value: 'tipo', sortable: false },
            ]
        }
    },

    mounted() {
        this.$refs.calendar.checkChange()
    },

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
    },

    watch: {
        date(val) {
            this.dateFormatted = this.formatDate(this.date)
        },
    },

    methods: {

        pegarDados: function () {



        },

        cadastrarConsulta: function () {
            var consulta = this.consulta;
            if (consulta.id == null) {
                if (consulta.consulta != "" && consulta.data != "" && consulta.valor != "" && consulta.idPaciente != 0 && consulta.idDentista != 0) {
                    consulta.data = this.date
                    consulta.valor = consulta.valor.replaceAll(".", "").replace(",", ".")

                    this.$http.post("cadastroConsulta", consulta)
                        .then((res) => {
                            if (res.data.status != undefined && res.data.status == 200) {
                                this.text = "Consulta cadastrada"
                                this.color = "success"
                                this.snackbar = true
                                this.dialog = !this.dialog
                                this.updateRange();
                                this.selectedOpen = false

                            } else if (res.data.status != undefined && res.data.status == 500) {
                                this.text = res.data.msg
                                this.color = "error"
                                this.snackbar = true
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.text = "Favor preencher todos campos obrigatórios"
                    this.color = "warning"
                    this.snackbar = true
                }
            } else {
                if (consulta.consulta != "" && consulta.data != "" && consulta.valor != "") {
                    consulta.data = this.date
                    consulta.valor = consulta.valor.replaceAll(".", "").replace(",", ".")

                    this.$http.post("editarConsulta", consulta)
                        .then((res) => {
                            if (res.data.status != undefined && res.data.status == 200) {
                                this.text = "Consulta atualizada"
                                this.color = "success"
                                this.snackbar = true
                                this.dialog = !this.dialog
                                this.updateRange();
                                this.selectedOpen = false

                            } else if (res.data.status != undefined && res.data.status == 500) {
                                this.text = res.data.msg
                                this.color = "error"
                                this.snackbar = true
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.text = "Favor preencher todos campos obrigatórios"
                    this.color = "warning"
                    this.snackbar = true
                }
            }
        },

        salvarPagamento: function () {
            var pagamento = this.consultaPagamento;
            pagamento.valor = this.valorPagar.replaceAll(".", "").replace(",", ".")

            if (pagamento.valor != "") {
                this.$http.post("efetuarPagamentoConsulta", pagamento)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.text = "Pagamento registrado"
                            this.color = "success"
                            this.snackbar = true
                            this.dialog = !this.dialog
                            this.updateRange();
                            this.selectedOpen = false
                            this.valorPagar = 0
                            this.consultaPagamento = { idConsulta: 0, valor: 0, tipo: 0, token: localStorage.getItem("token"), total: false }
                            this.$forceUpdate();

                        } else if (res.data.status != undefined && res.data.status == 500) {
                            this.text = res.data.msg
                            this.color = "error"
                            this.snackbar = true
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.text = "Favor preencher todos campos obrigatórios"
                this.color = "warning"
                this.snackbar = true
            }
        },

        deletarConsulta: function () {
            this.$http.post("deletarConsulta", this.consulta)
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.text = "Consulta deletada"
                        this.color = "success"
                        this.snackbar = true
                        this.dialog = !this.dialog
                        this.updateRange();
                        this.selectedOpen = false
                        this.dialogDelete = false

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        viewDay({ date }) {
            this.tab = 0
            this.consulta = {
                confirmacao: 1,
                consulta: "",
                consultaPagamentos: {},
                createdAt: "",
                data: date,
                dentistum: { dentista: '' },
                hora: "",
                id: null,
                idDentista: 0,
                idEmpresa: 0,
                idPaciente: 0,
                paciente: { paciente: '' },
                token: "",
                updatedAt: "",
                valor: "",
                token: localStorage.getItem("token"),
                idEmpresa: localStorage.getItem("idEmpresa")
            }
            this.consultaPagamentos = []
            this.valorPagar = 0
            this.totalConsulta = 0
            this.totalPago = 0
            this.tab = 0

            this.date = date
            this.focus = date
            this.selectedOpen = true
            this.novaConsulta = true
            this.consultaPagamento.total = true
        },
        getEventColor(event) {
            return event.color
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        showEvent({ nativeEvent, event }) {
            this.consulta = event.consulta


            this.$http.get("consultaId?id=" + event.consulta.id + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.consulta = res.data.consulta
                        this.consulta.token = localStorage.getItem("token")
                        this.consulta.color = this.colors[2];
                        if (this.consulta.confirmacao == 2) {
                            this.consulta.color = this.colors[0];
                        } else if (this.consulta.confirmacao == 3) {
                            this.consulta.color = this.colors[1];
                        }

                        var data = this.consulta.data.split(":")[0].split("T")[0].split("-")
                        this.date = data[0] + "-" + data[1] + "-" + data[2]



                        this.consultaPagamento = { idConsulta: 0, valor: 0, tipo: 0, token: localStorage.getItem("token"), total: false }
                        this.consultaPagamento.idConsulta = this.consulta.id
                        this.consultaPagamentos = this.consulta.consultaPagamentos;
                        var novoPagamento = []

                        var total = 0
                        for (var j = 0; j < this.consultaPagamentos.length; j++) {
                            var pagamento = this.consultaPagamentos[j];
                            total += parseFloat(pagamento.valor)

                            if (pagamento.tipo == 0) {
                                pagamento.tipo = "Dinheiro"
                            }
                            else if (pagamento.tipo == 1) {
                                pagamento.tipo = "Cartão de crédito"
                            }
                            else if (pagamento.tipo == 2) {
                                pagamento.tipo = "Cartão de débito"
                            }
                            else if (pagamento.tipo == 3) {
                                pagamento.tipo = "Pix"
                            }



                            pagamento.valor = parseFloat(pagamento.valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                            novoPagamento.push(pagamento)
                        }

                        if (parseFloat(this.consulta.valor) - total <= 0) {
                            this.consultaPagamento.total = true
                        }

                        this.totalPago = total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                        this.totalConsulta = parseFloat(this.consulta.valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                        this.consultaPagamentos = novoPagamento
                        this.novaConsulta = false
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });





            this.pegarDados()

            this.selectedOpen = true

            /*  const open = () => {
                 this.selectedEvent = event
                 this.selectedElement = nativeEvent.target
                 requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
 
             }
 
             if (this.selectedOpen) {
                 this.selectedOpen = false
                 requestAnimationFrame(() => requestAnimationFrame(() => open()))
             } else {
                 open()
             }
  */
            nativeEvent.stopPropagation()
        },

        updateRange() {

            this.$http.get("consultas?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        var newConsultas = []
                        for (var i = 0; i < res.data.dados.length; i++) {
                            res.data.dados[i].token = localStorage.getItem("token")
                            newConsultas.push(res.data.dados[i])
                        }

                        this.consultas = newConsultas

                        var novosEventos = []
                        for (let i = 0; i < this.consultas.length; i++) {


                            var [year, month, day] = this.consultas[i].data.split("T")[0].split('-')

                            var color = this.colors[2];
                            if (this.consultas[i].confirmacao == 2) {
                                color = this.colors[0];
                            } else if (this.consultas[i].confirmacao == 3) {
                                color = this.colors[1];
                            }

                            var inicioData = year + "-" + month + "-" + day + " " + this.consultas[i].hora

                            if (this.consultas[i].horaTermino != null) {
                                var terminoData = year + "-" + month + "-" + day + " " + this.consultas[i].horaTermino
                            } else {
                                var terminoData = year + "-" + month + "-" + day + " 00:00:10"
                            }

                            novosEventos.push({
                                name: this.consultas[i].paciente.paciente,
                                more_text: "",
                                start: inicioData,
                                end: terminoData,
                                color: color,
                                timed: false,
                                consulta: this.consultas[i]
                            })
                        }

                        this.events = novosEventos
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            this.$http.get("pacientes?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.pacientes = res.data.dados
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            this.$http.get("dentistas?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.dentistas = res.data.dados
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },

        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        getDay(date) {

            const WeekDays = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];

            return WeekDays[date.weekday];

        }

    },

    created() {

    },

    beforeMount() {
        var logado = localStorage.getItem("login")
        if (logado != undefined && logado == "true") {

        } else {
            this.$router.push('login')
        }
    },

    watch: {

    },
}
</script>
